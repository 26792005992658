import { useBaseContext } from "@/components/BaseContext";
import { BrowseMenuLinkItem } from "@/components/Header/BrowseMenu";
import { mcn } from "@/utils/mergeClassNames";
import { CompetenceBaseEx } from "@/server/backend-api";
import {
  getTopicsWithAllSubtopics,
  sortTopicsInBrowseBanner,
} from "@/utils/competenceTree";

type Props = {
  mobile?: boolean;
};

/**
 * Need this in order for topics to appear from top to bottom.
 * Otherwise it would be in the right order but from left to right.
 */
const orderMap: Record<number, string> = {
  0: "order-1",
  1: "order-3",
  2: "order-5",
  3: "order-7",
  4: "order-9",
  5: "order-11",
  6: "order-2",
  7: "order-4",
  8: "order-6",
  9: "order-8",
  10: "order-10",
};

const CompetenceItem = (props: {
  competence: CompetenceBaseEx;
  index: number;
}) => {
  const className = orderMap[props.index] ?? "";
  return (
    <BrowseMenuLinkItem href={props.competence.url} className={className}>
      {props.competence.name}
    </BrowseMenuLinkItem>
  );
};

type TopicCategoryProps = {
  title: string;
  competences: CompetenceBaseEx[];
  mobile?: boolean;
};

const CompetenceCategory = (
  props: TopicCategoryProps & { mobile?: boolean }
) => {
  if (props.competences.length === 0) return null;
  return (
    <div>
      <h2
        className={
          props.mobile
            ? "text-style-headline5 px-lg py-sm text-light-yellow"
            : "border-b border-b-blue mt-lg mb-xs pb-2xs"
        }
      >
        {props.title}
      </h2>
      <div
        className={mcn(
          !props.mobile && "grid grid-cols-2  gap-x-lg",
          props.mobile && "border-l border-yellow-100 mx-lg my-xs"
        )}
      >
        {props.competences.map((competence, index) => {
          return (
            <CompetenceItem
              key={competence.id}
              competence={competence}
              index={index}
            />
          );
        })}
      </div>
    </div>
  );
};

export const BrowseMenuTopicCategories = (props: Props) => {
  const baseContext = useBaseContext();
  const combinedCompetences = getTopicsWithAllSubtopics(
    baseContext.competenceTree
  );
  const sortedCompetences = sortTopicsInBrowseBanner(combinedCompetences);
  return (
    <div className={"col-span-full"}>
      <CompetenceCategory
        title="ECIU topics"
        competences={sortedCompetences}
        mobile={props.mobile}
      />
    </div>
  );
};
